.events {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.events .header {
  position: relative;
  margin: 22px 0 9px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}

.events .header .back {
  position: absolute;
  left: 16px;
  width: 26px;
}

.events .event-card{
  width: 343px;
  margin: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 7px 16px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  background: #1C2039;
}

.events .banner {
  width: 320px;
  height: 167px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.events .text {
  width: 100%;
  margin-top: 10px;
}

.events .publisher {
  font-size: 12px;
  transform: scale(0.75);
  transform-origin: 0;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #FFFFFFCC;
}

.events .name-num {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.events .event-card .name {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.events .event-card .number {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.02em;
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}