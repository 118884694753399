.homeDesktopDesktop .menu-bar {
    height: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }
  
  .homeDesktop .menu-button {
    width: 20.19px;
    height: 13.9px;
  }
  
  .homeDesktop .menu-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
  }
  
  .homeDesktop .calendar-button {
    width: 19px;
    height: 19px;
  }
  
  .homeDesktop .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 69px;
  }
  
  .homeDesktop .profile-avatar {
    width: 108px;
    height: 108px;
    border-color: #292929;
    border-radius: 39px;
    image-rendering: pixelated;
  }

/*noinspection CssOverwrittenProperties*/
.homeDesktop .profile-username {
    font-weight: 600;
    margin: 12px 0 8px;
    text-align: center;
    font-size: 28px;
    line-height: 28px;
  }
  
  .homeDesktop .profile-introduction {
    color: #8F8F8F;
    font-size: 16px;
    line-height: 17px;
  }

/*noinspection CssOverwrittenProperties*/
.homeDesktop .twoTit{
      display: flex;
      margin-top: 41px;
      align-items: center;
      text-align: center;
  }

  .homeDesktop .twoTit .split-line{
    width: 55.05px;
    height: 0;
    border: 1px solid rgba(143, 143, 143, 0.1);
    transform: rotate(90deg);
    margin: auto 27px;
  }
  .homeDesktop .twoTit .follow-up{
    font-weight: 600;
    font-size: 28px;
    line-height: 135%;
    margin-bottom: 11px;
  }
  .homeDesktop .twoTit .follow-down{
    width: 180px;
    height: 27px;
    font-family: PingFang SC;
    font-size: 18px;
    line-height: 150%;
  }

  .homeDesktop .twoTit .fan-up{
    font-weight: 600;
    font-size: 28px;
    line-height: 135%;
    margin-bottom: 11px;
  }
  .homeDesktop .twoTit .fan-down{
    width: 180px;
    height: 27px;
    font-family: PingFang SC;
    font-size: 18px;
    line-height: 150%;  
    color: #8F8F8F;
  }
  .homeDesktop .list {
    margin-top: 22px;
  }

  
  .homeDesktop .list-label {
    justify-content: space-between;
    margin: 0 0 21px;
  }
  

  .homeDesktop .list-label .title {
    min-width: 1200px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

/*noinspection CssOverwrittenProperties*/
.homeDesktop .list-label .title p {
    margin-left: 10px;
    height: 32px;
    font-weight: 600;
    font-size: 26px;
    line-height: 26px;
  }
  .homeDesktop .list-label svg{
    height: 32px;
    line-height: 32px;
  }
 
  .homeDesktop .list-label .hide-eye {
    width: 17.9px;
    height: 15px;
  }
  
  .homeDesktop .list-label .eye {
    width: 17.9px;
    height: 12.15px;
  }
  
  .homeDesktop .main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .homeDesktop .card-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 163px;
    height: 222px;
    margin: 0 8px 8px 0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  }
  
  .homeDesktop .card-grid {
    /* display: flex; */
    flex-direction: row;
    display: grid;
    position: relative;
    grid-template-columns: repeat(7, minmax(0, 1fr));

  }
  
  .homeDesktop .nft-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 163px;
    overflow: hidden;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  }
  
  .homeDesktop .nft-card .nft-image {
    width: 163px;
  }
  
  .homeDesktop .nft-card .nfo-image {
    width: 150px;
  }
  
  .homeDesktop .nft-card .biscuit {
    position: absolute;
    width: 161px;
  }
  
  .homeDesktop .card-title {
    width: 163px;
    height: 58px;
    padding: 12px 8px;
  }
  
  .homeDesktop .card-title .name {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 8px;
    color: #FFFFFF;
  }
  
  .homeDesktop .card-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .homeDesktop .card-info p {
    font-size: 12px;
    line-height: 12px;
    transform: scale(0.75);
  }
  
  .homeDesktop .card-info .price {
    color: #3F3F3F;
  }
  
  .homeDesktop .avatar-name {
    display: flex;
    align-items: center;
  }
  
  .homeDesktop .avatar-name p {
    position: relative;
    left: -5px;
    color: #8F8F8F;
  }
  
  .homeDesktop .avatar-name img {
    width: 16px;
    height: 16px;
    image-rendering: pi;
    border-width: 1px;
    border-color: #292929;
    border-radius: 39px;
  }
  

  .line{

      display: flex;
      margin-bottom: 30px;
  }
  .line .box{
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
      width: 163px;
      margin-right: 5px;
  }
    .line .box img{
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
        height: 233px;
    }
    .line .box .bottom{
      background: #FFFFFF;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
        display: block;
    }
    .line .box .title{
        padding: 12px 0 8px 5px;
        font-family: PingFang SC;
        font-size: 12px;
        line-height: 12px;
        color: #101010;
    }
    .line .box .contain{
        margin-left: 5px;
    }
    .box .bottom .contain{
        display: flex;
        justify-content: space-between;
        padding: 0 8px 10px 0;
    }
    .box .contain .left{
      color: #8F8F8F;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        font-size: 10px;
        line-height: 19px;
    }
    .box .contain .right{
      color: #3F3F3F;
      font-size: 10px;
      line-height: 19px;
    }





    /* .homeDesktop .no-content {
         justify-content: center;
        align-items: center; 
    }  */
  /* .homeDesktop .no-content .line{
    display: flex;
    flex-direction: row;
  }
  .homeDesktop .no-content img {
    margin-top: 91px;
  }
  .homeDesktop .no-content .des {
    margin-top: 19.11px;
  }
  .homeDesktop .no-content button {
    width: 100px;
    height: 38px;
    color: white;
    background: #2A7DF9;
    border-radius: 6px;
    margin-top: 106px;
  } */