.chartListContainer {
  position: relative;
  margin-top:20px;
  margin-left: 5%;
  margin-bottom: 125px;

  width: 90%;
  height:  calc(100vh - 295px);

  overflow-y: scroll;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.chartListContainer::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.avatarContainer .chartListAvatar {
  object-fit: cover;
  width:100%;
  height:100%;
  border-radius: 50%;
}

.chartListContainer .avatarContainer{
  height: 46px;
  width: 46px;

  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aiConversationContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.aiConversation {
  background: #282C49;
  border-radius: 12px 12px 12px 1px;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 248px;
  min-width: 40px;

  min-height: 41px;

  overflow:hidden;
}

.aiConversation .imgContainer{
  width: 100%;
  max-height: 250px;
  overflow: hidden;
}

.aiConversation .imgContainer .aiImg{
  border-radius: 12px 12px 0 0;
}

.aiConversation .aiSoloImg{
  width: 100%;
  border-radius: 12px 12px 12px 0;
}

.aiConversation .aiLink{
  margin: 0 12px 8px 12px;
  word-break: break-all;
  color: rgb(255,255,255,0.7);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 90%;
}

.aiConversation .dividerLine{
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  width: 90%;
}

.aiConversation .copyLink{
  margin: 8px 12px 8px 12px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 20px;
  width: 90%;
}

.aiConversation a:hover{
  color: #3287FB;
}

.copyLink .link{
  width: 85%;
  background-image: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  -webkit-background-clip: text;
  color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
}


.aiConversationText {
  margin: 12px;
  word-break: normal;
  color: rgb(255,255,255,0.7);
}

.contentContainer {
  display: flex;
  flex-direction: column;
}

.dateContainer {
  margin-left: 56px;
  margin-top: 6px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  color: rgb(255,255,255,0.7);
}


.userConversationContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.userConversation {
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  border-radius: 12px 12px 1px 12px;
  margin-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 248px;
  min-width: 40px;

  min-height: 41px;
}

.userConversationText {
  margin: 12px;
  word-break: normal;
  color: white;

  text-align: left;
}

.userDateContainer {
  margin-right: 56px;
  margin-top: 6px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  text-align: right;
  color: rgb(255,255,255,0.7);
}

