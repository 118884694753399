.commonDetailsContainer{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: 62px;
    background-color: #0E172A;
}

.commonDetailsContainer .content{
    flex: 1;
    background-color: #0E172A;
    position: relative;
}

.content .headerImg{
    width: 100vw;
    height: 100vw;

    max-height: 400px;

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    opacity: 0.8;
}

.content .saleInfo{
    position: absolute;
    width: 308px;
    background-color: #282C49;
    top: 350px;
    left: 50%;
    margin-left: -154px;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.saleInfo .saleHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 14px;
}

.saleHeader .saleSingleContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
}

.saleInfo .saleTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #BABABA;
}

.content .headerImg:before{
    content: '';
    color: #0E172A;
    display: list-item;
    padding-top: 100%;
    background: #0E172A;
}

.commonDetailsContainer .alert{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 77px;
    height: 40px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(4px);

    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 6px;
    justify-content: space-between;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.commonDetailsContainer .alertButton{
    background: rgba(255, 255, 255, 1);
    border-radius: 6px;
    width: 63px;
    height: 27px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.commonDetailsContainer .alertText{
    background-image:  linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    -webkit-background-clip: text;
    color: transparent;
}

.commonDetailsContainer .footer{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 11px 20px 20px 16px;
    background-color: #0E172A;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.footer .priceTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.6);
}

.footer .priceText{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    font-family: 'DIN Alternate';
}

.footer .priceContainer{
    display: flex;
    flex-direction: column;
}

.commonDetailsContainer .footer .paymentButton{
    width: 150px;
    height: 46px;
    background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.footer .saledButton{
    width: 150px;
    height: 46px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 6px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 13px;
    text-transform: lowercase;
    color: #FFFFFF;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.saleInfo .progressBarText{
    position: absolute;
    bottom: 3px;
    right: 11px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
}

.content .contentTitle{
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
    width: 80%;
    margin: auto;
}

.content .contentDesc{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-align: justify;
    letter-spacing: 0.04em;
    color: rgba(255, 255, 255, 0.6);

    overflow: hidden;
    width: 343px;
    margin: 10px auto;
}

.contentDesc .expand{
    float: right;
    clear: both;
    color: white;
}

.contentDesc::before{
    content: '';
    float: right;
    width: 0;
    height: calc(var(--height) - 22px);
    overflow: auto;
}

.content .infoCard{
    width: 343px;
    height: 169px;
    background-color: #282C49;
    margin: 24px auto;
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoCard .InfoCardTitle{
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    margin-top: 15px;
}

.infoCard .columnContainer{
    width: 303px;
    height: 12px;
    margin: 11px 0 7px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.columnContainer .columnTitle{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: white;
}

.columnContainer .columnText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.6);
}

.columnContainer .columnImgContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer .buttonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

