.avatarModalBackground{
    height: 100%;
    width: 100vw;
    z-index: 2;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    position: absolute;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.avatarModalBackground .modalContainer{
    width: 343px;
    height: 165px;
}

.avatarModalBackground .mainModal{
    width: 100%;
    height: 100%;
    background: #282C49;
    border-radius: 6px;
    position: relative;
    margin-top: -35px;
    /*border: 1px solid white;*/
}

.avatarModalBackground .fullAvatarContainer{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin-top: -58px;
}

.avatarModalBackground .modalContent{
    height: 112px;
    width: 100%;
    border-bottom: 1px solid #373D65;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatarModalBackground .modalLine{
    width: 50%;
    height: 50px;
    border-right: 1px solid #373D65;
    position: relative;
}

.avatarModalBackground .closeIcon{
    width: 20px;
    position: absolute;
    left: calc(100% - 10px);
    top: 50px;
}

.avatarModalBackground .avatarModalButtonContainer{
    position: absolute;
    top: 90px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.avatarModalBackground .avatarModalButtonSingleContainer{
    width: 121px;
    height: 35px;
    box-sizing:border-box;
    padding:1px;
    border-radius:6px;
    background-image:-webkit-linear-gradient(left,rgba(101, 72, 232, 1),rgba(139, 123, 239, 1) ,rgba(107, 199, 250, 1));
}

.avatarModalBackground .avatarModalButton{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:6px;
    background: #282C49;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}


