.dialogContainer{
    min-height: 480px;
    width: 280px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    background: #282C49;
    color: #FFFFFF;
}

.dialogContainer .picContainer{
    height: 293px;
    width: 100%;
    margin-bottom: 10px;
}

.dialogContainer .rowContainer{
    width: 90%;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 12px;
}

.dialogContainer .rowContainer .leftContainer{
    float: left;
    color: #959595;
}

.dialogContainer .rowContainer .rightContainer{
    text-align: right;
    width: 200px;
    float: right;
    word-break: break-all;
}

.dialogContainer .bottomContainer{
    margin-left: 13px;
    margin-right: 13px;
    margin-bottom: 9px;
    width: 90%;
    height: 61px;
    display: flex;
    flex-direction: row;
    position: relative;
}

.dialogContainer .bottomContainer .textContainer{
    position: absolute;
    bottom: 0;
    left: 58px;
    margin-left: 10px;
}

.dialogContainer .bottomContainer .logoContainer{
    position: absolute;
    right: 0;
    bottom: 3px;
}

