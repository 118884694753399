.collect .go-back {
  margin-top: 16px;
}

.collect .notice {
  margin: 0 16px;
}

.collect .headline {
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
  margin: 21px 0 12px;
}

.collect .notice .content {
  font-size: 12px;
  line-height: 24px;
  color: #8F8F8F;
  text-align: justify;
}

.collect .notice .content .review {
  color: #FF5151;
}

.collect .notice .content p {
  margin-bottom: 7px;
}

.collect .input-card {
  background: #FFFFFF;
  border-radius: 6px;
  margin: 35px 16px 0;
  padding: 16px;
}

.collect .input-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
}

.collect .input-container {
  display: flex;
}

.collect .input-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.collect input {
  min-width: 0;
  height: 50px;
  padding: 0 10px;
  font-size: 16px;
  line-height: 16px;
  color: #050505;
  background: #F8F8F9;
  border-radius: 6px;
  box-sizing: border-box;
}

.collect .input-error {
  height: 10px;
  margin: 10px 0;
  color: #FF5151;
  text-align: right;
}

.collect .action-container {
  display: flex;
  flex-direction: column;
  margin: 0 16px;
}

.collect button.next {
  margin: 40px 0 0;
  background-color: #2A7DF9;
  font-weight: 600;
}

.collect button.cancel {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin: 10px 0 47px;
  font-size: 16px;
  font-weight: 600;
  color: #8F8F8F;
  border: 0.5px solid #8F8F8F;
  box-sizing: border-box;
  border-radius: 6px;
}

.collect button:disabled.next {
  background-color: #DFDFDF;
}

.collect table {
  width: 100%;
}

.collect .label {
  width: 30%;
}

.collect .align-right {
  text-align: right;
  width: 70%;
}

.collect .table-value {
  text-align: right;
}

.collect div.address {
  margin: 0 0 18px;
}

.collect tr {
  height: 26px;
}

.collect .nft-image img {
  margin-bottom: 12px;
  border-radius: 6px;
}