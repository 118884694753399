.series-page {
  overflow: hidden;
}

.series-page .header {
  position: fixed;
  top: 16px;
  width: 100%;
  height: 26px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}

.series-page .header .back {
  position: absolute;
  left: 16px;
  width: 26px;
}

.series-page .header .name {
  width: 258px;
  text-align: center;
}

.series-page .banner {
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vw;
}

.series-page .banner-filter {
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vw;
  background: linear-gradient(180deg, #0F172A 0, rgba(15, 23, 42, 0) 128.2%);
  backdrop-filter: blur(3px);
  transform: rotate(-180deg);
}

.series-page .main {
  position: relative;
  z-index: 1;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.series-page .series-card {
  width: calc(330/375 *100vw);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.series-page .series-cover {
  width: calc(330/375 *100vw);
  height: calc(330/375 *100vw);
  border-radius: 8px;
  overflow: hidden;
}

.series-page .series-cover img {
  height: 100%;
}

.series-page .series-background {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.series-page .series-intro {
  position: relative;
  z-index: 1;
  width: calc(296/375 * 100vw);
  height: 96px;
  padding: 13px;
  bottom: 48px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  clip-path: polygon(15px 0%, 100% 0%, 100% 100%, 100% 100%, 0% 100%, 0% 15px);
  background: rgba(27, 39, 68, 0.49);
  backdrop-filter: blur(15px);
}

.series-page .series-intro:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  background-color:rgba(255, 255, 255, 0.15);
  clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
}

.series-page .series-title {
  position: relative;
  z-index: 1;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.06em;
  color: #FFFFFF;
}

.series-page .series-description {
  position: relative;
  z-index: 1;
  padding-left: 6px;
  margin-top: 8px;
}