.event-detail {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.event-detail .header {
  position: relative;
  margin-top: 22px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}

.event-detail .header .back {
  position: absolute;
  left: 16px;
  width: 26px;
}

.event-detail .main {
  width: 343px;
}

.event-detail .event-card{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-detail .banner {
  width: 100%;
  height: 167px;
  margin-top: 36px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.event-detail .text {
  width: 100%;
  margin-top: 10px;
}

.event-detail .publisher {
  font-size: 12px;
  transform: scale(0.75);
  transform-origin: 0;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #FFFFFFCC;
;
}

.event-detail .name-num {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.event-detail .event-card .name {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.02em;
}

.event-detail .event-card .number {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.02em;
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.event-detail .headline {
  margin-top: 25px;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.314679px;
}

.event-detail .divider {
  width: 100%;
  height: 0.5px;
  opacity: 0.4;
  background-color: #FFFFFF;
  margin: 25.91px 0 16.78px;
}

.event-detail .intro {
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: justify;
  word-break: break-all;
}

.event-detail .type-count {
  margin: 32.81px auto;
  width: 116px;
  height: 26px;
  border: 0.5px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-detail .card-grid{
  width: 353px;
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.event-detail .series-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 166px;
  height: 166px;
  margin-bottom: 11px;
  overflow: hidden;
  background: black;
  border-radius: 6px;
}

.event-detail .series-name {
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  transform: scale(0.9166);
  transform-origin: 0;
  position: absolute;
  color: white;
  left: 10px;
  bottom: 6px;
}

.event-detail .name-shadow {
  position: absolute;
  width: 166px;
  height: 34px;
  bottom: 0;
  background: linear-gradient(359.98deg, #161616 -24.77%, rgba(22, 22, 22, 0) 99.98%);
}