.ShareDialogContainer{
    height: 420px;
    width: 280px;

    background-position: center;
    position: relative;
    background-size: cover;

    display: flex;
    flex-direction: column;
}



.ShareDialogContainer  .profileAvatar{
    height: 62px;
    width: 62px;
    border: 4px solid white;
    border-radius: 50%;
    background: grey;
    margin-top: 43px;
    margin-left: auto;
    margin-right: auto;
}

.nameContainer{
    margin-top: 5px;
    color: white;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
    text-align: center;
}


.ShareDialogContainer   .introductionContainer{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin-top: 10px;
    color: white;
    width:100%;
    text-align:center;
}

.ShareDialogContainer   .listContainer{
   width: 100%;
   height: 83px;
   margin-top: 40px;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly ;
}

.ShareDialogContainer   .listContainer .nftContainer{
    width: 83px;
    border-radius: 6px;
    border: 2px solid rgba(244, 244, 244, 0.3);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ShareDialogContainer   .scanText{
    font-size: 10px;
    color: white;
    margin-top: 10px;
    width: 100%;
    text-align: center;
}

.ShareDialogContainer   .qrCode{
    height: 53px;
    width: 53px;
    margin-top: 33px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    border: 1px solid white;
}

.ShareDialogContainer   .qrCodeFull{
    height: 167px;
    width: 167px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
}



