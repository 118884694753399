.detailDesktop-page {
    margin-top: 38px;
    display: flex;
    justify-content: center;
  }
  .detailDesktop-page .photo{
      margin-right: 71px;
  }
  .detailDesktop-page .header {
    position: relative;
    margin-top: 6px;
    width: 343px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
  }
  
  .detailDesktop-page .header .back {
    position: absolute;
    left: 0;
    width: 26px;
  }
  
  .detailDesktop-page .id {
    margin: 1px 0 5px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 150%;
  }
  
  .detailDesktop-page .nft-container {
    position: relative;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 343px;
  }
  
  .detailDesktop-page .nft-container .biscuit {
    position: absolute;
    width: 320px;
  }
  
  .detailDesktop-page .nft-image {
    width: 551px;
    border-radius: 12px;
    filter: drop-shadow(0px 8px 11px rgba(0, 0, 0, 0.12));
  }
  
  .detailDesktop-page .collector-card {
    width: 343px;
    margin: 30px auto 12px;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 6px;
  }
  
  .detailDesktop-page .divider {
    width: 311px;
    height: 0.5px;
    background: #EBEBEB;
    margin: 14px 0 15.5px;
  }
  
  .detailDesktop-page .card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .detailDesktop-page .profile-container {
    display: flex;
    align-items: center;
  }
  
  .detailDesktop-page .avatar {
    width: 41px;
    height: 41px;
    border: 1px solid #E6E8EB;
    border-radius:22px;
    image-rendering: pixelated;
  }
  
  .detailDesktop-page .username {
    margin-left: 13px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #101010;
  }
  
  .detailDesktop-page .check-info {
    width: 64px;
    height: 25px;
    color: #0066FF;
    border: 1px solid #0066FF;
    border-radius: 32px;
  }
  .detailDesktop-page .information-card {
    /* width: 328px; */
    margin: 2px 0 0 71px;
    border-radius: 6px;
  }
  
  .detailDesktop-page .card-title {
    position: relative;
    margin-bottom: 10px;
    letter-spacing: -0.41px;
    color: #8F8F8F;
    text-align: left;
    display: flex;
    justify-content: space-between;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  .detailDesktop-page .card-p{
    margin-bottom: 36px;
    color: #000000;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
  }
    .information-card .bluedes{
    margin-bottom: 36px;
    color: #2A7DF9;
    display: flex;
    justify-content: space-between;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    }
  .information-card .marr{
    position: absolute;
    margin-left: 374px;
  }

  .information-card .bluedes2{
    margin-bottom: 10px;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #2A7DF9;
  }
  .information-card .bluedes2 span{
    padding-bottom: 2px;
    border-bottom: 1.5px solid #2A7DF9;
  }
  .information-card .key{
    width: 328px;
    margin-bottom: 39px;
    letter-spacing: -0.41px;
    color: #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  
  .detailDesktop-page button.claim {
    width: 504px;
    height: 50px;
    border-radius: 6px;
    background: #2A7DF9;
    color: #FFFFFF;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
  }
  
  .detailDesktop-page button:disabled.claim {
    background-color: #C4C4C4;
  }


