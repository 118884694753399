.auth-dialog {
  color: white;
}

.auth-dialog .claim-container .title {
  margin: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
}

.auth-dialog .divider {
  height: 0;
  border-top: solid #373D65 1px;
}

.auth-dialog .content {
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.auth-dialog .control {
  padding: 0 20px;
}

.auth-dialog .control button {
  width: 100%;
  height: 50px;
  color: white;
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  border-radius: 6px;
  font-size: 16px;
  line-height: 13px;
}

.auth-dialog .dialog-close {
  position: absolute;
  bottom: -70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth-dialog .close-line {
  width: 1px;
  height: 50px;
  background: #FFFFFF;
  opacity: 0.3;
}