.modalBackground{
    height:100vh;
    width: 100vw;
    z-index: 2;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    position: absolute;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBackground .modalContainer{
    width: 343px;
    height: 292px;
}

.modalBackground .mainModal{
    width: 100%;
    height: 222px;
    background: #282C49;
    border-radius: 6px;
}

.modalBackground .modalTitle{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #373D65;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.9);
}

.modalBackground .modalContent{
    height: 112px;
    width: 100%;
    border-bottom: 1px solid #373D65;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBackground .modalFooter{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
}

.modalBackground .leftFooter{
    width: 50%;
    height: 100%;
    border-right: 1px solid #373D65;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBackground .rightFooter{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBackground .modalLine{
    width: 50%;
    height: 50px;
    border-right: 1px solid #373D65;
    position: relative;
}

.modalBackground .closeIcon{
    width: 20px;
    position: absolute;
    left: calc(100% - 10px);
    top: 50px;
}