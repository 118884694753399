.detail-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #0F172A;
  width: 100vw;
}

.detail-page .header {
  position: relative;
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}

.detail-page .header .back {
  position: absolute;
  left: 16px;
  width: 26px;
}

.detail-page .header .name {
  width: 258px;
  text-align: center;
}

.detail-page .header .share {
  position: absolute;
  right: 16px;
  width: 17px;
  height: 17px;
  margin-top: 5px;
}

.detail-page .id {
  margin: 1px 0 5px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 150%;
}

.detail-page .nft-container {
  position: relative;
  min-height: 343px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detail-page .ai-nft {
  position: absolute;
  width: 54px;
  height: 18px;
  left: 10px;
  top: 10px;
  font-weight: 500;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
  background: rgba(28, 29, 32, 0.35);
  border: 0.45px solid rgba(28, 29, 32, 0.08);
  box-sizing: border-box;
  box-shadow: 0 1.8px 5.4px rgba(28, 29, 32, 0.08);
  backdrop-filter: blur(1.8px);  
  border-radius: 3px;
}

.detail-page .nfo-container {
  position: relative;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 343px;
  min-height: 343px;
}

.detail-page .nfo-container .biscuit {
  position: absolute;
  width: 320px;
}

.detail-page .nft-image {
  width: 343px;
  border-radius: 6px;
  filter: drop-shadow(0px 8px 11px rgba(0, 0, 0, 0.12));
}

.detail-page .chat-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 69px;
  border: 5px solid #0F172A;
  border-radius: 35px;
  margin:0 10px;
}

.detail-page .button-container{
  position: relative;
  margin-top: -31.5px;

  display: flex;
  flex-direction: row;
}

.detail-page .chat-button .button-name {
  margin-top: 7px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}

.detail-page .ar-container{
  width: 343px;
  height: 56px;
  margin: 8px auto 0 auto;
  
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
  background: white;
  background: no-repeat center center / 100% auto;

  position: relative;
}

.ar-container .gallery-content-container{
  padding-left: 18px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.gallery-content-container .gallery-name{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 141%;
  letter-spacing: 0.08em;
  color: #FFFFFF;
}

.gallery-content-container .inline-button-container{
  height: 20px;
  width: 75px;
  border: 1px solid white;
  filter: drop-shadow(0px 3.33333px 3.33333px rgba(0, 0, 0, 0.25));
  border-radius: 13.75px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.inline-button-container img{
  height: 10px;
}

.inline-button-container .button-content{
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  color: #FFFFFF;
  margin-left: 5px;
}

.detail-page .collector-card {
  width: 343px;
  margin: 12px auto 12px;
  padding: 16px;
  background: #282C49;
  border: 0.91573px solid #282C49;
  border-radius: 6px;
  z-index: 0;
}

.detail-page .divider {
  width: 311px;
  height: 0.5px;
  background: rgba(255, 255, 255, 0.2);;
  margin: 14px 0 15.5px;
}

.detail-page .card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail-page .profile-container {
  display: flex;
  align-items: center;
}

.detail-page .avatar {
  width: 41px;
  height: 41px;
  border-radius:22px;
  image-rendering: pixelated;
}

.detail-page .username {
  margin-left: 13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
}

.detail-page .check-info {
  width: 62px;
  height: 25px;
  position: relative;
  background: #282C49;
  border-radius: 32px;
}

.detail-page .check-info::before {
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -1;
  margin: -1px; /* !importanté */
  border-radius: inherit; /* !importanté */
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
}

.detail-page .check-info .btn-text {
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.detail-page .card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
}

.detail-page .information-card {
  width: 343px;
  margin: 0 0 32px;
  padding: 16px;
  background: #282C49;
  border: 0.91573px solid #282C49;
  border-radius: 6px;
}

.detail-page .table-label {
  font-weight: 500;
  font-size: 12px;
  width: 65px;
  height: 27px;
  vertical-align: top;
  color: rgba(255, 255, 255, 0.8);
}

.detail-page .table-value {
  font-size: 12px;
  height: 27px;
  text-align: right;
  vertical-align: top;
  color: rgba(255, 255, 255, 0.6);
}

.detail-page .information-content .address {
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.detail-page .information-content .address:hover {
  cursor: pointer;
}

.detail-page .information-content {
  width: 100%;
}

.detail-page button.claim {
  width: 343px;
  height: 50px;
  margin-bottom: 34px;
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}

.detail-page button:disabled.claim {
  background: rgba(255, 255, 255, 0.2);
}