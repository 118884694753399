.unbindModalBackground{
    height: 100vh;
    width: 100vw;
    z-index: 2;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    position: absolute;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.unbindModalBackground .input:focus{
    outline:none;
}

.unbindModalBackground .modalContainer{
    width: 343px;
    height: 419px;
}

.unbindModalBackground .mainModal{
    width: 100%;
    height: 349px;
    background: #282C49;
    border-radius: 6px;
    /*border: 1px solid white;*/
}

.unbindModalBackground .modalTitle{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #373D65;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.9);
}

.unbindModalBackground .modalContent{
    height: 223px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.unbindModalBackground .modalFooter{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 13px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
}

.unbindModalBackground .leftFooter{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unbindModalBackground .leftFooterButtonContainer{
    width: 138px;
    height: 100%;

    box-sizing:border-box;
    padding:1px;
    border-radius:6px;
    background-image:-webkit-linear-gradient(left,rgba(101, 72, 232, 1),rgba(139, 123, 239, 1) ,rgba(107, 199, 250, 1));
}

.unbindModalBackground .buttonBox{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100%;
    border-radius:6px;
    background: #282C49;
}

.unbindModalBackground .rightFooter{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unbindModalBackground .rightFooterButtonContainer{
    width: 138px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: -webkit-linear-gradient(left,rgba(101, 72, 232, 1),rgba(139, 123, 239, 1) ,rgba(107, 199, 250, 1));
}

.unbindModalBackground .modalLine{
    width: 50%;
    height: 50px;
    border-right: 1px solid #373D65;
    position: relative;
}

.unbindModalBackground .closeIcon{
    width: 20px;
    position: absolute;
    left: calc(100% - 10px);
    top: 50px;
}

.unbindModalBackground .contentSubtitle{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 13px;
    text-transform: lowercase;
    color: #FFFFFF;

    padding: 23px 0 13px 20px;
}

.unbindModalBackground .input{
    margin: 0 20px;
    width: 303px;
    height: 45px;
    border-radius: 6px;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    padding-left: 16px;
    font-size: 16px;
}

.unbindModalBackground ::placeholder{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: lowercase;
    color: rgba(255, 255, 255, 0.3);
}

.unbindModalBackground .codeText{
    position: absolute;
    color: white;
    top: 12px;
    right: 30px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    background-image: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.unbindModalBackground .countdownCodeText{
    position: absolute;
    color: rgb(255,255,255,.3);
    top: 12px;
    right: 30px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}