.header-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 108px;
  width: 100%;
  padding: 0 120px;
  border-bottom: 1px solid rgba(143, 143, 143, 0.3);
}

.header-desktop .menu {
  display: flex;
  align-items: center;
}

.header-desktop .website-logo {
  width: 128.48px;
  height: 40px;
}

.header-desktop #avatar-button {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.header-desktop img.avatar {
  width: 34px;
  height: 34px;
}

.header-desktop .connect-wallet {
  width: 102px;
  height: 40px;
  background: #2A7DF9;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}

.wallet-dialog .wallet-link {
  display: flex;
  margin-bottom: 26px;
}

.wallet-dialog .wallet-link .link {
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #2A7DF9;
  margin-right: 9px;
}

.wallet-dialog .address-label {
  font-size: 14px;
  line-height: 22px;
  color: #101010;
}

.wallet-dialog .address-value {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  margin: 16px 0 36px;
}

.wallet-dialog .address-notice {
  font-weight: 300;
  font-size: 12px;
  transform: scale(0.75);
  transform-origin: 0;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #FF2929;
}

.wallet-dialog .wallet-action {
  display: flex;
  justify-content: space-between;
}

.wallet-dialog .wallet-action button {
  width: 209px;
  height: 50px;
  border-radius: 6px;
}

.wallet-dialog .wallet-action button.bind {
  color: #FFFFFF;
  background: #2A7DF9;
}

.wallet-dialog .wallet-action button.close {
  color: rgba(16, 16, 16, 0.3);
  border: 0.5px solid rgba(16, 16, 16, 0.3);
  box-sizing: border-box;
}