.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
   margin-right: 10px;
}

.shape {
    width: 8px;
    height: 8px;
    background-color: #C4C4C4;
    border-radius: 100%;
    display: inline-block;
    margin-left: 10px;
    animation: anim 1.4s infinite;
    animation-fill-mode: both;
}

.shape:nth-child(1) {
    animation-delay: -1s;
}

.shape:nth-child(2) {
    animation-delay: -2s;
}

.shape:nth-child(3) {
    animation-delay: -3s;
}

@keyframes anim {

    0%, 80%, 100% {
        transform: scale(0.5);
        opacity: 0.5;
    }

    40% {
        transform: scale(1.0);
        opacity: 1;
    }

}