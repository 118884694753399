.authentication {
  padding: 16px;
  color: white;
}

.authentication .title{
  margin: 17px 0 7px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
}

.authentication .subtitle {
  margin-bottom: 60px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.authentication .input-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 10px;
}

.authentication .code-area {
  position: relative;
  margin-bottom: 58px;
}

.authentication .auth-input {
  height: 44px;
  width: 100%;
  font-size: 16px;
  margin-bottom: 30px;
  display: flex;
  background-color: #0F172A;
  border-bottom: 1px solid rgba(143, 143, 143, 0.2);
}

.authentication .auth-input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
}

.authentication .auth-input:focus {
  outline: none;
}

.authentication button.code {
  height: 20px;
  width: auto;
  position: absolute;
  bottom: 12px;
  right: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  background-color: transparent;
}

.authentication .notice {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FF3F3F;
}

.authentication button.confirm-auth {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  border-radius: 6px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
}

.authentication .authed {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.authentication .authed .avatar {
  width: 120px;
  margin-top: 56px;
  border: solid 6px #282C49;
  border-radius: 200px;
  box-sizing: border-box;
}

.authentication .authed .name {
  margin-top: 17.88px;
  font-weight: 600;
  font-size: 28px;
  line-height: 31px;
  text-align: center;
}

.authentication .authed .id-card {
  margin-top: 11.9px;
  font-size: 16px;
  line-height: 16px;
}

.authentication .authed .phone-container {
  position: relative;
  width: 343px;
  height: 50px;
  margin-top: 18.22px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background: #282C49;
  border-radius: 6px;
}

.authentication .authed .current {
  position: absolute;
  right: 10px;
  color: rgba(255, 255, 255, 0.5);;
}