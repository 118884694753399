.ar-page {
  overflow: hidden;
}

.ar-page .header {
  position: relative;
  margin: 16px 0 16px;
  height: 26px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}

.ar-page .header .back {
  position: absolute;
  left: 16px;
  width: 26px;
}