.loginDesktop {
    position: relative;
    min-height: 100vh;
    padding: 0 28px;
    background: url("../assets/bigbackground.png") rgba(0, 0, 0, 0.77);
    background-blend-mode: multiply;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  
  .loginDesktop .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loginDesktop .title-container .title {
    width: 192px;
    margin: 23vh auto 49.21px auto;
  }
  
  .loginDesktop .input-container {
    display: flex;
    margin-bottom: 16px;
    width: 319px;
    height: 50px;
  }
  
  .loginDesktop .input-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    background: #FFFFFF;
    backdrop-filter: blur(29px);
    border-radius: 6px;
  }
  
  button:disabled.code {
    color: #FFFFFF;
    background-color: #7C7C7C;
  }
  
  button.code {
    width: 101px;
    height: 50px;
    font-size: 14px;
    color: #3F3F3F;
    background: #F1F1F1;
    border-radius: 6px;
    margin-left: 18px;
  }
  
  .loginDesktop .agreement-container {
    margin-top: 4px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .loginDesktop .agreement-container .MuiCheckbox-root{
    position: relative;
    top: 0;
    margin-right: 10px;
  }
  
  .loginDesktop .agreement-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 17px;
  }
  
  .loginDesktop .next-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 319px;
  }
  
  button.next.checked {
    background: #2A7DF9;
  }
  
  button.next {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    height: 50px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    background: #7C7C7C;
    border-radius: 6px;
  }
  
  
  #cancel {
    background: transparent;
    color: #aa8964;
    filter: drop-shadow(0px 1px 1px rgba(172, 139, 103, 0.4));
    border: 1px solid;
    border-radius: 4px;
  }
  
  .loginDesktop .validate {
    color: red;
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    position: relative;
    transform: scale(0.83, 0.83);
  }
  
  .loginDesktop input {
    min-width: 0;
    height: 50px;
    padding: 0 10px;
    font-size: 16px;
    line-height: 16px;
    color: #050505;
    background: #F1F1F1;
    border-radius: 6px;
    box-sizing: border-box;
  }
  
  input::placeholder{
    color: #3F3F3F;
  }
  /* .agreement-container input{
    width: 14px;
    height: 14px;
    border: 1px solid #FD2222;
    box-sizing: border-box;
  } */
  