.countDownContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.countDownContainer .paymentText{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FF3F3F;
}

.countDownContainer .clockContainer{
    height: 41px;
    position: relative;
}

.countDownContainer .clockNumber{
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: 'Chakra Petch';

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.countDownContainer .colon{
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    width: 11px;
    color: #8B7BEF;

    text-align: center;
    margin: 0 5px;
}

.verifyCodeText{
    color: rgb(255,255,255,.3);
}
