.aiChartHeaderImg {
  position: absolute;
  top: 0;
  width: 100%;
  /*height: 100%;*/
  /*min-height: 200px;*/
  background-repeat: no-repeat;
  background-size:100% auto;
  border: none;
  opacity: 0.7;
}

.aiChartHeaderImg:before{
  content: '';
  display: list-item;
  padding-top: 100%;

  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(14, 23, 42, 1)) ;
}

.aiChartHeaderText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  position: relative;
  margin-top: 106px;
  margin-left: 21px;
  color: rgba(255, 255, 255, 0.8);
}

.aiChartHeaderDayCountText {
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.aiChartLine {
  width: 27px;
  height: 3px;
  margin-left: 21px;
  margin-top: 10px;

  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  border-radius: 5px;
  position: relative;
}