.allSetContainer{
    display: flex;
    flex-direction: column;

    position: relative;
    overflow: hidden;
}

.allSetContainer .topPicContainer {
    position: absolute;
    width: 100%;
    height: 440px;
    background-repeat: no-repeat ;
    background-size: cover;
    background-position: center;
    border: none;
    transition: 2s;
    top: 0;
    left:0;
}


.allSetContainer .seriesAndAuthorContainer{
    position: absolute;
    top: 261px;
    left: 23px;
    transition: 2s;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
}

.allSetContainer .nameContainer{
    position: absolute;
    top: 284px;
    left: 23px;
    transition: 2s;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #FFFFFF;
    margin-top: 0 !important;
    width: calc(100% - 46px);
}

.allSetContainer .topSetContainer{
    display: flex;
    flex-direction: row;
    margin-top: 364px;
}

.allSetContainer .countdownContainer{
    font-family: 'DIN Alternate';
    font-style: normal;
    font-weight: 700;
    line-height: 156%;
    text-align: justify;
    font-size: 40px;
    color: #FFFFFF;
    width: 100px;
    height: 140px;
    padding-left: 23px;
    padding-top: 30px;

    position: relative;
}

.countdownContainer .text{
    position: absolute;
    transition: 1s;
}

.countdownContainer .line {
    border-top: 1px solid #D9D9D9;
    opacity: 0.4;
    width: 58px;
    transform: rotate(150deg);
    margin-top: 60px;
}

.countdownContainer .totalNumber{
    margin-left: 5px;
    margin-top: -40px;

    color: #FFFFFF;
    opacity: 0.5;
    font-size: 20px;
    width:50px;
    text-align: right;
}

.allSetContainer .swiperContainer{
    width: calc(100vw - 100px);
    padding-left: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
}

.swiperContainer .swiper {
    width: 100%;
    height: 100%;
}

.swiperContainer .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    width: 115px!important;
    height: 115px!important;

    margin-top: auto;
    margin-bottom: auto;
    border-radius: 8px;
    overflow: hidden;
    /*transition: 2s;*/
}
 .swiperContainer .swiper-slide-active{
     zoom: 1.2;
     /*transform: scale(1.21);*/
     /*-webkit-transform: scale(1.21);*/
     /*width:140px;*/
     /*height: 140px;*/
     /*transition: 1s;*/
}

.swiperContainer .swiper-slide-next {
    /*transition: 1s;*/
}

.swiperContainer .swiper-slide-prev {
    /*transition: 1s;*/
}

.swiperContainer .icon{
    position: absolute;
    z-index: 99;
    width: 63px;
    top: 110px;
    left: 48px;
}

.processContainer{
   width: 91%;
   height: 1px;
   background: rgba(255, 255, 255, 0.2);
   margin: 40px auto;

   position: relative;
}

.processContainer .bar{
    position: absolute;
    height: 2px;
    background: #65718E;
    width: 40px;
    top: -0.5px;
    transition: 0.5s;
}

.descWrapper .descContainer{
    position: relative;
    left: 50%;
    top: 0;
    width: 344px;
    margin-left: -172px;
    background: #252946;
    border-radius: 6px;
    transition: 1s;
}

.descContainer .title{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;
    color: #FFFFFF;

    padding-left: 19px;
    padding-top: 15px;
    padding-bottom: 8px;
}

.descContainer .content{
    ont-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    text-align: justify;
    color: #FFFFFF;

    padding-left: 19px;
    padding-right: 14px;
    padding-bottom: 16px;
}

.allSetContainer .swiperCardContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    overflow: hidden;
    position: relative;
}

.swiperCardContainer .playButton{
    position: absolute;
    height: 38px;
    width: 38px;
}

.swiperCardContainer img{
    width: auto;
    height: 100%;
    pointer-events:none;
    -webkit-touch-callout: none;
    border-radius: 8px;
}

.allSetContainer .descWrapper{
    min-height: 200px;
    width: 100%;
    margin-bottom: 20px;
}

.allSetContainer .video-container{
    width: 100vw;
    height: 100vw;
}

