.collect-result {
  padding: 0 16px;
}

.collect-result .headline {
  margin-top: 21px;
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
}

.collect-result .notice-content {
  margin-top: 12px;
  font-size: 12px;
  line-height: 18px;
  text-align: justify; 
  color: #8F8F8F;
}

.collect-result .resualt-image {
  position: relative;
  margin: 83px 0 27px;
}

.collect-result .result-body {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.collect-result .result-alert {
  margin-bottom: 35px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}

.collect-result .nft-data {
  margin-bottom: 40px;
  padding: 10px 20px;
  border: 1px solid #E6E8EC;
  box-sizing: border-box;
  border-radius: 6px;
}

.collect-result .nft-data .table-value {
  text-align: right;
}

.blue p {
  color: #2A7DF9;
}

button.blue {
  background-color: #2A7DF9;;
}

.red p {
  color: #FF0000;;
}

button.red {
  background-color: #FF0000;;
}

.collect-result .action-button{
  margin-bottom: 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
}

.collect-result .back-button{
  margin-bottom: 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  color: #8F8F8F;
  border: 0.5px solid #8F8F8F;
}

.collect-result video.loading-video {
  width: 114px;
  height: 114px;
  margin-top: 112px;
}

.collect-result .loading-text {
  margin-bottom: 196px;
}

.collect-result .refresh-notice {
  margin-bottom: 6px;
}