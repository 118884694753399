.cardContainer {
    width: 343px;
    height: 499px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    margin-bottom: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardContainer .card {
    width: 307px;
    height: 330px;
    border-radius: 20px;
    position: relative;

    margin-top: 18px;
    overflow: hidden;

    display: flex;
    justify-content: center;
}

.cardContainer:last-child{
    margin-bottom: 60px;
}

.cardContainer .name{
    color: rgba(255, 255, 255, 0.8);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.cardContainer .title{
    color: #FFFFFF;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-top: 10px;
}

.cardContainer .button{
    width: 234px;
    height: 37px;
    background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    margin-top: 24px;
}

.card .img {
    border-radius: 20px;
    width: 100%;
    height: 307px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.card .tagContainer {
    position: absolute;
    right: 12px;
    top: 16px;

    background: rgba(28, 29, 32, 0.35);
    border: 1px solid rgba(28, 29, 32, 0.08);
    box-shadow: 0 2px 6px rgba(28, 29, 32, 0.08);
    backdrop-filter: blur(2px);
    border-radius: 3px;

    height: 22px;
    min-width: 65px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    padding: 3px 6px;
}

.card .cardAvatar{
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -23px;
    height: 46px;
    width: 46px;
    border: 4px solid #0F172A;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.card .avatarImg{
    width: 100%;
    border-radius: 50%;
}

.tagContainer .onSaleIcon {
    height: 13px;
    width: 13px;
}