.cropper .cropper-content {
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    color: #101010;
}

.cropper .cropper-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.3px;
}

.cropper .confirm {
    margin-left: 5vw;
    width: 90vw;
    height: 50px;
    background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 75px;
}

.cropper .cropper-dashed{
    border: 1px solid rgb(255,255,255,0.5);
}

.cropper .cropper-view-box{
    box-shadow: 0 0 0 2px #FFFFFF;
}

.cropper .cropper-face{
    /*background-color: #1B1966;*/
    /*opacity: 0.5;*/
}

.cropper .cropper-line{
    opacity: 1;
    background-color: #878B95;
}

.cropper  .line-n{
    height: 2px;
}

.cropper  .line-e{
    width: 2px;
}

.cropper .line-s{
    height: 2px;
}

.cropper .line-w{
    width: 2px;
}

.cropper .cropper-point{
    background-color: #878B95;
    opacity: 1;
}

.cropper .point-nw{
    height: 10%;
    min-height: 10px;
    width: 5px;
    left: -6px;
    top: -3px;
}

.cropper .point-n{
    height: 5px;
    width: 12%;
    min-width: 12px;
    left: -3px;
    top: -6px;
}

.cropper .point-ne{
    height: 10%;
    min-height: 10px;
    width: 5px;
    right: -6px;
    top: -3px;
}

.cropper .point-e{
    height: 5px;
    width: 12%;
    min-width: 12px;
    right: -6px;
    top: -3px;
}

.cropper .point-sw{
    height: 10%;
    min-height: 10px;
    width: 5px;
    bottom: -3px;
    left: -6px;
}

.cropper .point-w{
    height: 5px;
    width: 12%;
    min-width: 12px;
    top: calc(100% + 4px);
    left: -6px;
}

.cropper .point-s{
    height: 5px;
    width: 12%;
    min-width: 12px;
    bottom: -6px;
    left: auto;
    right: -6px;
}

.cropper .point-se {
    height: 10%;
    min-height: 10px;
    width: 5px;
    right: -6px;
}

.cropper .cropper-center{
    display: none;
}