.paymentOrderListContainer{
    min-height: 100vh;
    width: 100vw;
    background: #0E172A;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: scroll;
}

.paymentOrderListContainer .header{
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.paymentOrderListContainer .myOrderListText{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
}

.paymentOrderListContainer .paymentOrderCardContainer{
    background: #282C49;
    border-radius: 6px;
    width: 341px;
    height: 123px;
    margin-bottom: 30px;
}

.paymentOrderListContainer .cardContent{
    padding: 16px 8px 15px 10px;
    width: 100%;
    height: 100%;
}

.cardContent .orderNumber{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 6px;
    word-break: break-all;
}

.cardContent .line{
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    margin-bottom: 12px;
}

.cardContent .imgContainer{
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}

.cardContent .mainContent{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.cardContent .rightContent{
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cardContent .rightContent .rightContentMainTitle{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
}

.cardContent .rightContent .bottomContainer .priceText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.8);
}

.cardContent .rightContent .bottomContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.paymentOrderListContainer .paymentSuccess{
    background-image: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    -webkit-background-clip: text;
    color: transparent;
}
