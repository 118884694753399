.user .menu-bar {
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.user .back {
  position: absolute;
  left: 16px;
  width: 26px;
}

.user .menu-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}

.user .share-button {
  width: 17px;
  height: 17px;
}

.user .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user .profile-avatar {
  width: 78px;
  height: 78px;
  border-width: 4px;
  border-color: #292929;
  border-radius: 39px;
  image-rendering: pixelated;
}

.user .profile-username {
  font-weight: 600;
  font-size: 20px;
  margin: 12px 0 8px;
  line-height: 20px;
  text-align: center;
}

.user .profile-introduction {
  font-size: 12px;
  line-height: 12px;
  color: #8F8F8F;
}

.user .list {
  margin: 35px 0 45px;
}

.user .list-label {
  width: 343px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px 19px;
}

.user .list-label .title {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

.user .list-label .title p {
  margin-left: 10px;
}

.user .list-label .hide-eye {
  width: 17.9px;
  height: 15px;
}

.user .list-label .eye {
  width: 17.9px;
  height: 12.15px;
}

.user .main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user .card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 163px;
  height: 222px;
  margin: 8px;
  background: #1C2039;
  border-radius: 6px;
  border: 1px solid #282C49;
}

.user .card-grid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.user .nft-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 163px;
  overflow: hidden;
  border-radius: 6px 6px 0 0;
}

.user .nft-card .nft-image {
  width: 163px;
}

.user .nft-card .nfo-image {
  width: 150px;
}

.user .nft-card .biscuit {
  position: absolute;
  width: 161px;
}

.user .card-title {
  width: 163px;
  height: 58px;
  padding: 12px 8px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
}

.user .card-title .name {
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 8px;
  color: #FFFFFF;
}

.user .card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user .card-info p {
  font-size: 12px;
  line-height: 12px;
  transform: scale(0.75);
  transform-origin: 0;
}

.user .card-info .price {
  color: #3F3F3F;
}

.user .avatar-name {
  display: flex;
  align-items: center;
}

.user .avatar-name p {
  position: relative;
  margin-left: 4px;
  color: #8F8F8F;
}

.user .avatar-name img {
  width: 16px;
  height: 16px;
  image-rendering: pi;
  border-width: 1px;
  border-color: #292929;
  border-radius: 39px;
}

.user .no-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user .no-content img {
  width: 211.83px;
  margin-top: 91px;
}

.user .no-content .des {
  margin-top: 19.11px;
  color: #8F8F8F;
}

.user .no-content button {
  width: 100px;
  height: 38px;
  color: white;
  background: #2A7DF9;
  border-radius: 6px;
  margin-top: 106px;
}