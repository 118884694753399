.hex {
    position: relative;
}

.hex .container {
    mask: url("./assets/inlineFrame.png") center center/108px 98px no-repeat;
    -webkit-mask: url("./assets/inlineFrame.png") center center/108px 98px no-repeat;
    width: 108px;
    height: 98px;
    position: absolute;
    top: 7px;
    left: 12px;
}

.hex .imgContainer{
    width: 120px;
    height: 110px;
}

.hex .nftAvatarIcon{
    position: absolute;
    z-index: 2;
    width: 40px;
    bottom: -10px;
    left: 82px;
}

.circleAvatarContainer{
    height: 116px;
    width: 116px;
    background: transparent;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

    display: flex;
    align-items: center;
    justify-content: center;

    border: 6px solid #282C49;

    position: relative;
}

.circleAvatarContainer .imgAvatar{
   height: 100%;
    border-radius: 50%;
}

.circleAvatarContainer .circleAvatarIcon{
    position: absolute;
    height: 40px;
    bottom: -16px;
    right: 0;
}