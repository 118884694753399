.claim {
  color: white;
}

.claim .claim-container .title {
  margin: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
}

.claim .divider {
  height: 0;
  border-top: solid #373D65 1px;
}

.claim .content {
  padding: 20px;
}

.claim .content input {
  width: 100%;
  height: 50px;
  padding: 10px;
  background: #282C49;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 6px;
}

.claim .content input::placeholder {
  color: #ffffff;
}

.claim .control {
  padding: 0 20px;
}

.claim .control button {
  width: 100%;
  height: 50px;
  color: white;
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  border-radius: 6px;
}

.claim .control button:disabled {
  background: rgba(255, 255, 255, 0.2);
}

.claim.loading {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.claim.claim.loading img {
  margin-top: 39px;
}

.claim.loading .text {
  margin-top: 17px;
  height: 22px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.success {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success img {
  position: relative;
  right: 10px;
}

.success .text {
  margin-top: 8.45px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF
}