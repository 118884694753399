.sidebar {
  color: white;
}

.sidebar .MuiPaper-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar .profile {
  width: 270px;
  display: flex;
  margin-top: 75px;
  flex-direction: column;
  align-items: center;
}

.sidebar .profile-avatar {
  width: 78px;
  height: 78px;
  border-width: 5px;
  border-color: #282C49;
  border-radius: 39px;
  image-rendering: pixelated;
}

.sidebar .profile-username {
  font-weight: 600;
  font-size: 20px;
  margin: 12px 0 8px;
  line-height: 20px;
  text-align: center;
}

.sidebar .profile-introduction {
  font-size: 12px;
  line-height: 12px;
  color: #8F8F8F;
}

.sidebar .content {
  width: 216px;
  height: 100%;
  position: relative;
}

.sidebar .title-container {
  position: relative;
  height: 57.24px;
  margin-top: 42px;
  background: #282C49;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar .title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  margin-top: 13.92px;
}

.sidebar .wallet-icon {
  width: 16.7px;
  position: absolute;
  top: 20.06px;
  left: 17.44px;
}

.sidebar .nft-num {
  position: relative;
  top: -4px;
  font-size: 10px;
  transform: scale(0.75);
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6);
}

.sidebar .menu-list li {
  margin: 40px 3px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}

.sidebar .menu-list li img {
  width: 24px;
  height: 22px;
  margin-right: 13.61px;
  overflow: hidden;
}

.sidebar .logout {
  width: 100px;
  height: 38px;
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}

.sidebar .logout-container {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%);
}