.commonPageContainer{
    background-color: #0F172A;
    display:flex;
    min-height: 100vh;
    width: 100vw;
    padding-bottom:62px;
    flex-direction:column;
}

.commonPageContainer .content{
    flex: 1;
    margin-top: 70px;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
}

.footerContainer{
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 41px 0;
    color: white;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: rgba(19, 27, 46, 0.8);
}

.footerContainer .footerImg{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -20px;
}

.footerImg > div {
    display: flex;
    flex: auto;
    justify-content: center;
}

.commonPageContainer .guideFooter{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 82px;
    padding: 11px 7px 9px 16px;
    background: rgba(255, 255, 255, 0.3);
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.guideFooter .button{
    background: rgba(255, 255, 255, 1);
    border-radius: 6px;
    width: 63px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.guideFooter .buttonText{
    background-image: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    -webkit-background-clip: text;
    color: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.footerImg .leftImg{
    height: 20px;
}

.footerImg .middleImg{
    height: 20px;
}

.footerImg .rightImg{
    height: 20px;
}

.commonPageContainer .headerContainer{
    position: fixed;
    left: 0;
    right: 0;
    top:0;
    padding: 20px;
    background-color: #0F172A;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.headerContainer .leftImg{
    height: 24px;
}

.headerContainer .rightImg{
    height: 30px;
    width: 98px;
}





