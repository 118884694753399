.collection-list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.collection-list .header {
  position: relative;
  margin-top: 22px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}

.collection-list .header .back {
  position: absolute;
  left: 16px;
  width: 26px;
}

.collection-list .header .menu-title {
  width: 258px;
  text-align: center;
}

.collection-list .volume-data {
  display: flex;
  align-items: center;
  width: 343px;
  height: 64px;
  margin: 32px 0 29px;
  box-sizing: border-box;
  border: 0.5px solid rgba(255, 255, 255, 0.4);
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.06));
  border-radius: 6px;
}

.collection-list .count-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collection-list .count-container img {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.collection-list .count-container .number {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.collection-list .divider {
  width: 0.5px;
  height: 28px;
  background: rgba(255, 255, 255, 0.4);
}

.collection-list .card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 163px;
  height: 222px;
  margin: 8px;
  background: #1C2039;
  border-radius: 6px;
  overflow: hidden;
}

.collection-list .card-grid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.collection-list .nft-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 163px;
  overflow: hidden;
}

.collection-list .nft-card .nft-image {
  width: 163px;
  height: 163px;
  border-radius: 6px 6px 0 0;
}

.collection-list .nft-card .nfo-image {
  width: 150px;
}

.collection-list .nft-card .biscuit {
  position: absolute;
  width: 161px;
}

.collection-list .card-title {
  width: 163px;
  height: 58px;
  padding: 12px 8px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
}

.collection-list .card-title .name {
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 8px;
  color: #FFFFFF;
}

.collection-list .card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collection-list .card-info p {
  font-size: 12px;
  line-height: 12px;
  transform: scale(0.75);
  transform-origin: 0;
}

.collection-list .card-info .price {
  color: #3F3F3F;
}

.collection-list .avatar-name {
  display: flex;
  align-items: center;
}

.collection-list .avatar-name p {
  position: relative;
  margin-left: 4px;
  color: #8F8F8F;
}

.collection-list .avatar-name img {
  width: 16px;
  height: 16px;
  image-rendering: pi;
  border-width: 1px;
  border-color: #292929;
  border-radius: 39px;
}

.collection-list .no-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collection-list .no-content img {
  width: 211.83px;
  margin-top: 91px;
}

.collection-list .no-content .des {
  margin-top: 19.11px;
  color: #8F8F8F;
}

.collection-list .no-content button {
  width: 100px;
  height: 38px;
  color: white;
  background: #2A7DF9;
  border-radius: 6px;
  margin-top: 106px;
}