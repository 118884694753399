.aiChartContainer {
  height: 100vh;
  min-width: 100%;
  position: absolute;
  top:0;
  background-color: #0E172A;
}

.aiChartBackIcon {
  position: absolute;
  left: 16px;
  top: 16px;
}

.copyAlert{
  position: absolute;
  background: rgba(16, 16, 16, 0.5);
  border-radius: 6px;
  width: 146px;
  height: 122px;
  left: 50%;
  top: 50%;
  margin-top: -61px;
  margin-left: -73px;

  color: #FFFFFF;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

