.addNewCardPageContainer{
    background: #0E172A;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;

    color: white;
    padding-left: 16px;
    padding-top: 84px;
    padding-right: 16px;
}

.addNewCardPageContainer .dutyText{
    width: 100%;
    color: #FF3F3F;
    text-align: left;
    margin: -20px auto 0 auto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
}

.addNewCardPageContainer .mainTitle{
    line-height: 48px;
    font-size: 32px;
    font-weight:600 ;
    margin-bottom: 8px;
}

.addNewCardPageContainer .subTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.subTitle:last-child{
    margin-bottom: 30px;
}

.addNewCardPageContainer .inputTitle{
    color: rgba(255, 255, 255, 0.8);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.addNewCardPageContainer input{
    margin-top: 10px;
    width: calc(100vw - 32px);
    height: 40px;
    background: transparent;
    border-bottom: 1px solid #282C49;
    color: white;
    padding-left: 0;
    font-size: 16px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileInput:focus{
    outline: none;
}

.addNewCardPageContainer .checkLine{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 34px;
}

.checkLine .agreement{
    background-image: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.checkLine .agreementText{
    margin-left: 10px;
}

.addNewCardPageContainer .input:focus{
    outline:none;
}

.addNewCardPageContainer ::placeholder{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: lowercase;
    color: rgba(255, 255, 255, 0.3);
    margin-left: 10px;
}

.addNewCardPageContainer .codeText{
    position: absolute;
    color: white;
    top: 18px;
    right: 0;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    background-image: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.addNewCardPageContainer .countdownCodeText{
    position: absolute;
    color: rgb(255,255,255,.3);
    top: 24px;
    right: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.addNewCardPageContainer .confirmButton{
    width: 343px;
    height: 50px;
    background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 20px auto 37px auto;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 13px;
    text-transform: uppercase;
    color: #FFFFFF;
}

