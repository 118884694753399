.settingsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  min-height: 100vh;
  min-width: 100vw;
  background-color: #0F172A ;
}

.settingsContainer .backButton {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 25px;
  width: 25px;

  position: absolute;
  left: 15px;
  top: 18px;
}


.settingsContainer .profileAvatar {
  width: 108px;
  height: 108px;
  border-width: 6px;
  border-color: #282C49;
  border-radius: 55px;
  image-rendering: pixelated;
}

.settingsContainer .cameraIcon {
  position: absolute;
  height: 31px;
  width: 31px;
  left: 77px;
  top: 77px;
}

.settingsContainer .avatarContainer {
  position: relative;
  margin-top: 99px;
  border-radius: 55px;
}

#cropImg {
  min-height: 200px;
  max-height: 400px;
}

.settingsContainer .confirm {
  width: 90vw;
  height: 50px;
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  margin-top: 35px;
}

.settingsContainer .nicknameContainer {
  width: 90vw;
  margin-top: 37px;
}

.settingsContainer .nickname {
  color: rgba(255, 255, 255, 0.8);
  margin-left: 5px;
  margin-bottom: 5px;
  font-weight: 600;
}

.settingsContainer .nicknameInput {
  display: flex;
  align-items: center;
  height: 50px;
  border: 1px solid rgba(143, 143, 143, 0.2);
  border-radius: 6px;
  font-size: 14px;
  color: #FFFFFF;
}

.nicknameInput input {
  outline: none;
  background-color: #0F172A;
}

.settingsContainer .logoContainer {
  height: 24px;
  width: 24px;
  margin-left: 21px;
  margin-right: 18px;
}

/* cropper style */

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

/*.cropper-face {*/
/*  background-color:inherit !important;*/
/*}*/

/*.cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw, .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line {*/
/*  display:none !important;*/
/*}*/


.cropper-view-box {
  outline: inherit !important;
}

.cropper-container{
  margin-top: 100px;
}