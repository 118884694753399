.import {
  padding: 33px 20px;
  color: white;
}

.import .input-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  margin-bottom: 13px;
}

.import Select {
  width: 100%;
  height: 45px;
  background: #3B4066;
  border-radius: 6px;
  margin-bottom: 24px;
  padding-left: 17px;
  font-size: 16px;
}

.import input {
  width: 100%;
  height: 45px;
  background: #282C49;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  margin-bottom: 24px;
  padding-left: 15px;
  font-size: 16px;
}

.import input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.import .notice {
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 21px;
}

.import button {
  width: 100%;
  height: 50px;
  color: white;
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  border-radius: 6px;
}

.import button:disabled {
  background: rgba(255, 255, 255, 0.2);
}

.import-dialog .dialog-close {
  position: absolute;
  bottom: -70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.import-dialog .close-line {
  width: 1px;
  height: 50px;
  background: #FFFFFF;
  opacity: 0.3;
}