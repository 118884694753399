.inputFooter {
  position:absolute;
  bottom:0;
  margin-top: 10px;
  height: 125px;
  width: 100%;
  padding: 16px 0 0 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);;
}

.question-input-box{
  outline-style: none ;
}

.inputFooter .question-list-container {
  overflow-x: scroll;
}

.inputFooter .question-list-container::-webkit-scrollbar {
  display: none;
}

.inputFooter .question-list {
  white-space: nowrap;
}

.inputFooter .question-list .question {
  display: inline-block;
  line-height: 23px;
  margin-right: 10px;
  padding: 0 10px;
  font-size: 12px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  border-radius: 12px;
}

.inputFooter .input-container {
  margin: 12px 0 0;
  display: flex;
  align-items: center;
}

.inputFooter .question-input-box {
  flex: auto;
  height: 39px;
  padding-left: 11px;
  background: #282C49;
  border-radius: 4px;
  color: rgb(255,255,255,1);
}

.inputFooter .question-input-box::placeholder {
  font-size: 12px;
  line-height: 17px;
  color: rgb(255,255,255,0.7);
}

.inputFooter .send-button {
  width: 50px;
  height: 23px;
  margin: 0 12px;
  font-size: 12px;
  color: #FFFFFF;
  border-radius: 23px;
}

.inputFooter .send-button.able {
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
}

.inputFooter .send-button.disable {
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
}