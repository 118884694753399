.home {
  color: #ffffff;
}

.home .menu-bar {
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.home .menu-button {
  width: 24px;
  height: 24px;
}

.home .menu-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}

.home .galaxy-logo {
  width: 99.98px;
  height: 30.83px;
}

.home .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home .profile-avatar {
  width: 78px;
  height: 78px;
  border-width: 4px;
  border-color: #292929;
  border-radius: 39px;
  image-rendering: pixelated;
}

.home .profile-username {
  font-weight: 600;
  font-size: 20px;
  margin: 12px 0 8px;
  line-height: 20px;
  text-align: center;
}

.home .profile-introduction {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #8F8F8F;
}

.home .list {
  margin: 35px 0 45px;
}

.home .list-label {
  width: 343px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px 19px;
}

.home .list-label .title {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

.home .list-label .title p {
  margin-left: 10px;
}

.home .list-label .hide-eye {
  width: 17.9px;
  height: 15px;
}

.home .list-label .eye {
  width: 17.9px;
  height: 12.15px;
}

.home .main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home .card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 163px;
  height: 222px;
  margin: 8px;
  background: #1C2039;
  border-radius: 6px;
  border: 1px solid #282C49;
}

.card-container:last-child{
  margin-bottom: 80px;
}

.home .card-grid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.home .nft-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 163px;
  overflow: hidden;
  border-radius: 6px 6px 0 0;
}

.home .nft-card .ai-nft {
  position: absolute;
  width: 54px;
  height: 18px;
  left: 10px;
  top: 10px;
  font-weight: 500;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
  background: rgba(28, 29, 32, 0.35);
  border: 0.45px solid rgba(28, 29, 32, 0.08);
  box-sizing: border-box;
  box-shadow: 0 1.8px 5.4px rgba(28, 29, 32, 0.08);
  backdrop-filter: blur(1.8px);  
  border-radius: 3px;
}

.home .nft-card .nft-image {
  width: 163px;
}

.home .nft-card .nfo-image {
  width: 150px;
}

.home .nft-card .biscuit {
  position: absolute;
  width: 161px;
}

.home .card-title {
  width: 163px;
  height: 58px;
  padding: 12px 8px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
}

.home .card-title .name {
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 8px;
  color: #FFFFFF;
}

.home .card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home .card-info p {
  font-size: 12px;
  line-height: 12px;
  transform: scale(0.75);
  transform-origin: 0;
}

.home .card-info .price {
  color: #3F3F3F;
}

.home .avatar-name {
  display: flex;
  align-items: center;
}

.home .avatar-name p {
  position: relative;
  margin-left: 4px;
  color: #8F8F8F;
}

.home .avatar-name img {
  width: 16px;
  height: 16px;
  image-rendering: pi;
  border: 0.5px solid #C4C4C4;
  border-radius: 39px;
}

.home .no-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home .no-content img {
  width: 266.07px;
  margin-top: 91px;
}

.home .no-content .des {
  margin-top: 19.11px;
  color: #8F8F8F;
  font-size: 12px;
}

.home .no-content button {
  width: 100px;
  height: 38px;
  color: white;
  background: #2A7DF9;
  border-radius: 6px;
  margin-top: 106px;
}

.home .fuction-content {
  display: flex;
  margin-top: 20px;
}

.claim-dialog .dialog-close {
  position: absolute;
  bottom: -70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.claim-dialog .close-line {
  width: 1px;
  height: 50px;
  background: #FFFFFF;
  opacity: 0.3;
}

.home .claim-button {
  width: 92px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #282C49;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}

.home .claim-button img {
  margin-right: 10.24px;
}

.home .calendar-button {
  margin-left: 16px;
  width: 92px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #282C49;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}

.home .import-button {
  margin-left: 16px;
  width: 92px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #282C49;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}

.home .calendar-button img {
  margin-right: 10.24px;
}

.home .import-button img {
  margin-right: 10.24px;
}