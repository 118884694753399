.paymentOrderPageContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    padding-bottom: 62px;
    background-color: #0E172A;
    align-items: center;
}

.paymentOrderPageContainer .content {
    flex: 1;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paymentOrderPageContainer .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 11px 20px 20px 16px;
    background-color: #0E172A;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.footer .priceTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.6);
}

.footer .priceText {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    font-family: 'DIN Alternate';
}

.footer .priceContainer {
    display: flex;
    flex-direction: column;
}

.paymentOrderPageContainer .footer .paymentButton {
    width: 118px;
    height: 46px;
    background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.content .header {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 16px;
}

.content .avatar {
    width: 343px;
    height: 343px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar .avatarImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 307px;
    height: 307px;
    border-radius: 16px;
    overflow: hidden;
}

.content .paymentContainer {
    width: 342px;
    height: 200px;
    background: #282C49;
    border-radius: 6px;
    margin-top: 25px;
    margin-bottom: 40px;
}

.paymentContainer .paymentTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
}

.paymentContainer .paymentContentContainer {
    width: 302px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.paymentContentContainer .paymentSubtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}

.paymentContentContainer .paymentContent {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: rgba(255, 255, 255, 0.4);
}

.paymentContainer .paymentLine {
    width: 302px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin: 10px auto 0 auto;
}

.paymentContentContainer .paymentColorContent {
    background-image: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    -webkit-background-clip: text;
    color: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.04em;
}

.paymentOrderPageContainer .adm-modal-mask {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

.paymentOrderPageContainer .countdownContainer {
    width: 100%;
    height: 40px;
    background: rgb(255, 255, 255, 0.3);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.countdownContainer .countdownContentContainer {
    width: 343px;
    heigth: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.countdownContentContainer .leftTimeText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.8);
}

.paymentOrderPageContainer .bankIconContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.paymentOrderPageContainer .waringText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #FF3F3F;
    margin-top: 29px;
    margin-bottom: 8px;
}

.paymentOrderPageContainer .cancelButton {
    width: 118px;
    height: 46px;
    border: 1px solid #282C49;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.8);

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 16px;
}

.paymentOrderPageContainer .timeoutButton {
    width: 118px;
    height: 46px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 13px;
    text-transform: lowercase;

    color: rgba(255, 255, 255, 0.5)
}