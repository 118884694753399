.helpModalBackground{
    height: 100vh;
    width: 100vw;
    z-index: 2;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    position: absolute;
    left: 0;
    top: 0;
}

.helpModalBackground .modalContainer{
    width: 343px;
    height: 292px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.helpModalBackground .mainModal{
    width: 100%;
    height: 318px;
    background: #282C49;
    border-radius: 6px;
    margin-top: -35px;
    /*border: 1px solid white;*/
}

.helpModalBackground .modalTitle{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #373D65;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.9);
}

.helpModalBackground .modalContent{
    height: 260px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.helpModalBackground .modalLine{
    width: 50%;
    height: 50px;
    border-right: 1px solid #373D65;
    position: relative;
}

.helpModalBackground .closeIcon{
    width: 20px;
    position: absolute;
    left: calc(100% - 10px);
    top: 50px;
}

.helpModalBackground .qrCodeContainer{
    height: 138px;
    width: 138px;
    box-sizing:border-box;
    padding:4px;
    border-radius:20px;
    background-image:-webkit-linear-gradient(left,rgba(101, 72, 232, 1),rgba(139, 123, 239, 1) ,rgba(107, 199, 250, 1));
}

.helpModalBackground .qrCodeBox{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100%;
    border-radius:18px;
    background: #282C49;
}

.helpModalBackground .contentText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 14px;
}