.library-page {
  color: #ffffff;
}

.library-page .menu-bar {
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.library-page .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.library-page .library-card {
  position: relative;
  width: 343px;
  height: 147px;
  padding: 43px 0 0 23px;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer;
}

.library-page .library-card .mask {
  position: absolute;
  z-index: 0;
  top: -1px;
  left: -1px;
  width: 345px;
  height: 149px;
  border-radius: 8px;
}

.library-page .library-name {
  position: relative;
  z-index: 1;
  font-weight: 600;
  font-size: 21px;
  line-height: 141%;
  letter-spacing: 0.08em;
  color: #FFFFFF;
}

.library-page .library-button {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  width: 98px;
  height: 26.13px;
  filter: drop-shadow(0px 4.35556px 4.35556px rgba(0, 0, 0, 0.25));
  border-radius: 18px;
}

.library-page .library-button::before {
  content: '';
  position: absolute;
  left: 0;
  width: 196px;
  height: 52.26px;
  border: 1px solid #FFFFFF;
  transform: scale(0.544445);
  transform-origin: 0;
  border-radius: 36px;
}

.library-page .library-button iframe {
  width: 15.24px;
  height: 15.24px;
}

.library-page .button-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #FFFFFF;
  margin-left: 4.36px;
}