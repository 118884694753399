.collectionListContainer{
    background-color: #0F172A;

    min-height: 100vh;
    width: 100vw;
    padding-bottom:62px;

    display:flex;
    flex-direction:column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.collectionListContainer .nftListContainer{
    flex: 1;
    color: white;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-bottom: 15px;
    margin-top: 16px;
    overflow: scroll;
    max-width: 340px;
}

.collectionListContainer .collectionCardContainer{
    width: 160px;
}

.collectionCardContainer:last-child{
    margin-bottom: 30px;
}

.collectionListContainer .collectionImgContainer{
    width: 100%;
    height: 160px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-top: 16px;
}

.collectionListContainer .collectionItemTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 10px;
}

.collectionListContainer .collectionCardBackIcon{
    position: absolute;
    left: 20px;
    top: 20px;
    width:20px;
}

.collectionListContainer .collectionCardTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;

    margin-top: 19px;
}

.collectionListContainer .footerContainer{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    color: white;
    background: #0E172A;

    display: flex;
    align-items: center;
    justify-content: center;
}

.collectionListContainer .collectionCardButton {
    width: 328px;
    height: 50px;
    background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 13px;
    text-transform: uppercase;
}
