.App {
  min-height: 100vh;
  background: #0F172A;
  color: #ffffff;
}

textarea:focus, input:focus{
  outline: none;
}

