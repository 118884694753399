.login {
  position: relative;
  min-height: 100vh;
}

.login video {
  object-fit: cover;
  writing-mode: 100vw;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

.login .main {
  height: 100vh;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  padding: 0 28px;
}

.login .title-container {
  margin: 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login .title-container .title {
  width: 200px;
  margin: 102px 0 85px;
}

.login .input-container {
  display: flex;
  margin-bottom: 16px;
}

.login .input-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

button:disabled.code {
  color: #FFFFFF;
  background-color: #7C7C7C;
}

button.code {
  width: 101px;
  height: 50px;
  font-size: 14px;
  color: #3F3F3F;
  background: #F1F1F1;
  border-radius: 6px;
  margin-left: 10px;
}

.login .agreement-container {
  margin-top: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.login .agreement-container .MuiCheckbox-root{
  position: relative;
  top: 0;
  margin-right: 10px;
}

.login .agreement-label {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 17px;
}

.login .next-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.login button.next.checked {
  background: linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%);
  backdrop-filter: blur(29px);
}

button.next {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  background: #7C7C7C;
  border-radius: 6px;
}

#cancel {
  background: transparent;
  color: #aa8964;
  filter: drop-shadow(0px 1px 1px rgba(172, 139, 103, 0.4));
  border: 1px solid;
  border-radius: 4px;
}

.login .validate {
  color: red;
  font-size: 12px;
  line-height: 12px;
  height: 12px;
  position: relative;
  transform: scale(0.83, 0.83);
}

.login input {
  min-width: 0;
  height: 50px;
  padding: 0 10px;
  font-size: 16px;
  line-height: 16px;
  color: #050505;
  border-radius: 6px;
  box-sizing: border-box;
}

input::placeholder{
  color: #3F3F3F;
}
