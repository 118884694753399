.bankCardManagePageContainer{
    background: #0E172A;
    width: 100vw;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.bankCardManagePageContainer .title{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 18px;
}

.bankCardManagePageContainer .addCardIcon{
    width:306px;
    margin-top: 64px;
}

.bankCardContainer{
    width: 280px;
    height: 73px;
    margin-top: 64px;
}

.bankCardContainer .bankCardImg{
    height: 18px;
}

.bankCardContainer .arrowIcon{
    height: 8px;
    margin-left: 10px;
}


.bankCardContainer .bankCardTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.bankCardContainer .bankCardText{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    margin-left: 16px;
}

.bankCardTitle .leftTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bankCardTitle .rightTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rightTitle .rightTitleText{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #F8FAFC;
}

.bankCardContainer .numberContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.375em;
    color: #FFFFFF;

    border-bottom: 1px solid rgba(255, 255, 255, 0.2);;
    padding-bottom: 10px;
}

.bankCardContainer .dotsContainer{
    height: 10px;
}