.collect-dialog .nft-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 11px;
  width: 188px;
  height: 188px;
  border-radius: 6px;
  overflow: hidden;
}

.collect-dialog .address-container {
  width: 312px;
  margin: 21px 0 16px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.collect-dialog input.address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 12px;
  width: 247px;
  height: 38px;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 6px;
}

.collect-dialog .code-container {
  width: 312px;
  margin: 13px 0 16px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.collect-dialog input.code {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 12px;
  width: 142px;
  height: 38px;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 6px;
}

.collect-dialog input.address::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.collect-dialog button.code {
  width: 90px;
  height: 38px;
  margin-left: 15px;
}

.collect-dialog .dialog-action {
  height: 101px;
  display: flex;
  justify-content: center;
  align-items: center;
}