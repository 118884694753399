.agreement {
  background: #0F172A;
  color: #FFFFFF;
}

.agreement .agreement-content {
  padding: 0 16px 40px;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
}

.agreement .about-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.3px;
}